<template>
  <a-card :bordered="false">
    <div>
      <div class="table-page-search-wrapper">
        <a-form layout="inline" v-if="!queryParam.id">
          <a-row :gutter="48">
            <a-col :md="4" :sm="24">
              <a-form-item label="租金ID">
                <a-input v-model="queryParam.rent_bill_id" placeholder="输入租金账单ID" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="1" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :data="loadData"
        :scroll="{ x: 1000 }"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 140px">
          {{ (text / 100).toFixed(2) | numberFormat }}
        </div>
      </s-table>
    </div>
  </a-card>
</template>

<script>
import {
  rent_bill_reduce_all_list
} from '@/api/rent_bill'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import { STable } from '@/components'

export default {
  name: 'TableList',
  components: {
    CreateForm,
    STable
  },
  data () {
    return {
      pageNum: 1,
      dataSource: [],
      loading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      queryParam: {},
      mdl: {},
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return rent_bill_reduce_all_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      columns: [
        {
          fixed: 'left',
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          width: 50
        },
        {
          title: '减免金额',
          dataIndex: 'reduce',
          scopedSlots: { customRender: 'money_render' },
          width: 140,
          ellipsis: true
        },
        {
          title: '日期',
          dataIndex: 'create_time',
          width: 180,
          ellipsis: true
        },
        {
          title: '所有人',
          dataIndex: 'property_owner',
          width: 120,
          customRender: (text) => this.$Dictionaries.electricity_meter_property_owner[text],
          ellipsis: true
        },
        {
          title: '位置',
          dataIndex: 'location',
          ellipsis: true,
          width: 120,
          customRender: (text) => this.$Dictionaries.location.find((item) => !!item[text])[text]
        },
        {
          title: '操作人',
          dataIndex: 'create_by',
          width: 140,
          ellipsis: true
        }
      ]
    }
  }
}
</script>
